var exports = {};
exports = {
  A: {
    A: {
      "1": "B",
      "2": "J D E F A rB"
    },
    B: {
      "1": "L G M N O P Q R S V W X Y Z a b c d e f g T h H",
      "129": "C K"
    },
    C: {
      "1": "EB FB GB HB IB JB KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S V W X Y Z a b c d e f g T h H kB",
      "2": "sB gB",
      "260": "0 1 2 3 4 5 6 7 8 9 G M N O j k l m n o p q r s t u v w x y z AB BB CB DB",
      "804": "I i J D E F A B C K L tB uB"
    },
    D: {
      "1": "KB LB MB hB NB iB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R S V W X Y Z a b c d e f g T h H kB vB wB",
      "260": "FB GB HB IB JB",
      "388": "0 1 2 3 4 5 6 7 8 9 u v w x y z AB BB CB DB EB",
      "1412": "G M N O j k l m n o p q r s t",
      "1956": "I i J D E F A B C K L"
    },
    E: {
      "129": "A B C K L G 1B mB eB fB 2B 3B 4B nB oB 5B",
      "1412": "J D E F zB 0B",
      "1956": "I i xB lB yB"
    },
    F: {
      "1": "7 8 9 AB BB CB DB EB FB GB HB IB JB KB LB MB NB OB PB U QB RB SB TB UB VB WB XB YB ZB aB bB cB dB P Q R jB S",
      "2": "F 6B 7B",
      "260": "2 3 4 5 6",
      "388": "0 1 G M N O j k l m n o p q r s t u v w x y z",
      "1796": "8B 9B",
      "1828": "B C eB pB AC fB"
    },
    G: {
      "129": "HC IC JC KC LC MC NC OC PC QC RC SC TC UC nB oB",
      "1412": "E DC EC FC GC",
      "1956": "lB BC qB CC"
    },
    H: {
      "1828": "VC"
    },
    I: {
      "1": "H",
      "388": "aC bC",
      "1956": "gB I WC XC YC ZC qB"
    },
    J: {
      "1412": "A",
      "1924": "D"
    },
    K: {
      "1": "U",
      "2": "A",
      "1828": "B C eB pB fB"
    },
    L: {
      "1": "H"
    },
    M: {
      "1": "T"
    },
    N: {
      "1": "B",
      "2": "A"
    },
    O: {
      "388": "cC"
    },
    P: {
      "1": "fC gC hC mB iC jC kC lC mC nC",
      "260": "dC eC",
      "388": "I"
    },
    Q: {
      "260": "oC"
    },
    R: {
      "260": "pC"
    },
    S: {
      "260": "qC"
    }
  },
  B: 4,
  C: "CSS3 Border images"
};
export default exports;